import React from 'react';

function Profile() {
  return (
    <div className="mb-6">
      <img src="profile.jpg" alt="Foto de perfil" className="w-24 h-24 rounded-full mx-auto mb-4" />
      <h1 className="text-xl font-semibold">Derli Lopez</h1>
      <p>🍉 Activista por la Paz | Promotora #DDHH | Opiniones personales</p>
    </div>
  );
}

export default Profile;
