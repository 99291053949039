import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Profile from './Profile';
import Links from './Links';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <div className="flex items-center justify-center min-h-screen bg-gray-100" style={{ backgroundImage: `url(/hero.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center relative z-10">
          <Profile />
          <Routes>
            <Route path="/" element={<Links />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
