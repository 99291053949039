import React, { useState, useEffect } from 'react';
import { FaGlobe, FaTwitter, FaInstagram, FaFacebook, FaTiktok, FaLinkedin, FaShoppingCart, FaShareAlt } from 'react-icons/fa';
import { CiShare2, CiShoppingCart } from "react-icons/ci";
import { RiTwitterXFill  } from "react-icons/ri";
import { PiTiktokLogoLight, PiFacebookLogo, PiInstagramLogo } from "react-icons/pi";

const iconMap = {
  Web: <FaGlobe size={25}/>,
  X: <RiTwitterXFill size={25}/>,
  Instagram: <PiInstagramLogo size={25}/>,
  Facebook: <PiFacebookLogo size={25}/>,
  TikTok: <PiTiktokLogoLight size={25}/>,
  LinkedIn: <FaLinkedin size={25}/>,
  Shop: <CiShoppingCart size={25}/>
};

function Links() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetch('/data/links.json')
      .then(response => response.json())
      .then(data => setLinks(data));
  }, []);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Mi Perfil',
        url: window.location.href
      })
      .then(() => console.log('Perfil compartido exitosamente'))
      .catch(error => console.error('Error al compartir', error));
    } else {
      // Fallback para navegadores que no soportan la API de Web Share
      alert('La función de compartir no está soportada en este navegador. Copia el enlace manualmente: ' + window.location.href);
    }
  };

  return (
    <div className="space-y-2">
      {links.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center bg-red-500 text-white py-3 rounded-lg transition-colors duration-300 hover:bg-red-700"
        >
          <span className="mr-2">{iconMap[link.type]}</span>
          {link.label}
        </a>
      ))}
      <button
        onClick={handleShare}
        className="flex items-center justify-center bg-white text-gray-700 py-2 rounded-lg transition-colors duration-300 hover:bg-gray-100 w-full border border-gray-300"
      >
        <CiShare2 className="mr-2" size={25}/>
        Compartir Perfil
      </button>
      
    </div>
  );
}

export default Links;
