import React from 'react';

function Footer() {
  return (
    <footer className="mt-8 text-center">
      <p className="text-gray-500 text-xs">
        Por <a href="https://cencomer.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 no-underline">Luis Cabezas</a> - <a href="https://cencomer.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 no-underline">Cencomer.com</a>
      </p>
    </footer>
  );
}

export default Footer;
